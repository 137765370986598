import React from 'react';
import './App.css';
import Mainpage from "./components/MainPage/mainpage";

function App() {
    return (
       <Mainpage/>
    );
}

export default App;
